export const menuList = [
  {
    title: "회사소개",
    titleLink: "",
    list: ["사업소개", "인사말·조직도", "비전·미션·핵심가치", "연혁", "CI"],
    listLink: "/about_us",
  },
  { title: "디지털전환DT", titleLink: "", list: ["스마트그린 ESG기술", "SaaS 기반 DT 플랫폼", "테스트베드 & 스페이스"], listLink: "/digitaltwin" },
  { title: "글로벌 ESG R&D", titleLink: "", list: ["탈탄소화 컨설팅", "글로벌인증 및 기준", "탄소중립 에너지 컨설팅"], listLink: "/globalit" },
  { title: "디자인", titleLink: "", list: ["ESG 디자인 솔루션", "도시 토목 생태 스마트경관", "갤러리"], listLink: "/design" },
  { title: "토탈친환경 계획", titleLink: "", list: ["토탈친환경 컨설팅 및 인증", "환경성능 시뮬레이션"], listLink: "/consulting" },
  { title: "뉴스·교육", titleLink: "", list: ["보도자료", "아카데미"], listLink: "/news" },
  { title: "실적", titleLink: "", list: ["주거/비주거부문", "프로젝트 실적", "클라이언트", "검측 장비"], listLink: "/trackrecord" },
  { title: "솔루션", titleLink: "/#/design", list: [], listLink: "" },
];
