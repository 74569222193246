import React from "react";
import "./styles/Footer.scss";
import { ReactComponent as Logo } from "../assets/logo_simple.svg";
import { useNavigate } from "react-router-dom";
import { menuList } from "../utils/menu";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer id="footer">
        <div className="inner">
          <div className="sitemap_wrap">
            {menuList.map((menu: { title: string; titleLink: string; list: string[]; listLink: string }, index: number) => {
              return menu.titleLink === "" ? (
                <div className="sitemap" key={`${menu.title}_${index}`}>
                  <div className="title">{menu.title}</div>
                  <ul>
                    {menu.list.map((text: string, index2: number) => {
                      return (
                        <li key={`${text}_${index2}`}>
                          <span
                            onClick={() => {
                              navigate(menuList[index].listLink, { state: { tap: index2 } });
                            }}
                          >
                            {text}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null;
            })}
          </div>
          <div className="office_info_wrap">
            <div className="logo_wrap">
              <Logo className="logo" />
            </div>
            <div className="info_wrap">
              <div className="info">
                <span>법인명 : ㈜아키테코그룹</span>
                <span>대표이사 : 고정림</span>
                <span>사업자등록번호 : 733 - 81 - 02288</span>
                <span>주소 : 서울 송파구 법원로11길 11 현대지식산업센터 IB동 1101,1102호</span>
              </div>
              <div className="info">
                <span>이메일 : architeco@architeco-geo.com</span>
                <span>문의 사항은 위 메일로 보내주시길 바랍니다.</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
